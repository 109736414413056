import { enableScroll } from '../utils/scroll';

export default function () {
  window.addEventListener('pageshow', function (event) {
    const historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
    if (historyTraversal) {
      // Handle page restore.
      enableScroll();
    }
  });
}
