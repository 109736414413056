export default function (heading) {
  let content = '<span class="word-animate">';

  // console.log(heading);
  // console.log(heading.textContent);
  // console.log(heading.textContent.length);

  for (let i = 0; i < heading.textContent.length; i++) {
    if (heading.textContent[i] === ' ') {
      content += '</span>';
      content += heading.textContent[i];
      content += '<span class="word-animate">';
    } else {
      if (heading.textContent[i] === '-') {
        content += '</span>';
        content += '<span class="word-animate">';
      }
      content += '<span class="letter-animate" style="transition-delay: ' + i * 0.01 + 's">';
      content += heading.textContent[i];
      content += '</span>';
    }
  }
  content += '</span>';

  heading.innerHTML = content;
}
