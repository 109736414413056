import sal from '../utils/sal';
import wrap from "../utils/letters-wrap";

export default function ($) {
  // Default elements
  sal({});

  // HERO
  const hero = document.querySelectorAll('.hero');

  hero.forEach(function (block) {
    const heroTitle = block.querySelector('.hero__title');
    wrap(heroTitle);
    const letters = heroTitle.querySelectorAll('.letter-animate');

    letters.forEach(function (letter, i) {
      letter.style.transitionDelay = `${(i * 0.01) + 0.6}s`;
    });

    setTimeout(function () {
      block.classList.add('animate');
    }, 1000);
  });

  //ARTICLE CONTENT
  sal({
    selector: '.single-layout__content .elementor-widget-theme-post-content .elementor-widget',
  });
}
