export function scrollerInit(block) {
  const $dots = block.find('.slick-dots li');
  let $scroller = $('<div class="scroller"></div>');
  $scroller.css('width', 100/$dots.length + '%');
  block.find('.slick-dots').addClass('scroller-track');
  block.find('.slick-dots').append($scroller);
  $dots.find('button').css('opacity', '0');
}

export function scrollerUpdate(block, i) {
  block.find('.scroller').css('transform', 'translateX(' + (100 * i) + '%)');
}
