import { scrollerInit, scrollerUpdate } from './slick-scroller';

export default function ($) {
  const $heroSliders = $('.results-slider');

  function dividersUpdate($slider, direction) {
    const $slides = $slider.find('.slick-slide');

    if (direction) $slides.removeClass('divider-shown');

    $slides.each(function (i) {
      if (!direction) {
        if (!$(this).hasClass('slick-active')) return;
        if ($slides.eq(i + 1).hasClass('slick-active')) $(this).addClass('divider-shown');
        return;
      }

      if (!$(this).hasClass('slick-active') || !$slides.eq(i + 1).hasClass('slick-active')) return;
      $slides.eq(i + direction).addClass('divider-shown');
    });
  }

  $heroSliders.each(function () {
    const $this = $(this);
    let args = {
      infinite: false,
      mobileFirst: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    if ($this.hasClass('results-slider--widget') && !$this.hasClass('results-slider--narrow')) {
      args = {
        infinite: false,
        mobileFirst: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 998,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      };
    }
    $this.slick(args);
    scrollerInit($this);

    $this.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      dividersUpdate($this, nextSlide - currentSlide);
      scrollerUpdate($this, nextSlide);
    });

    dividersUpdate($this);
  });
}
