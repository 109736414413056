export default function postNav($) {
  var windowWidth = window.innerWidth;
  var windowHeight = window.innerHeight;
  var $block = $('.post-nav');
  var is_safari = false;
  var pageUrl = window.location.href;

  pageUrl = pageUrl.substring(0, pageUrl.indexOf('#'));

  if (!$block.length) return;
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) is_safari = true;

  var $content = $('.elementor-widget-theme-post-content '),
      $headings = $content.find('.elementor-heading-title').not('.skip-from-nav'),
      $nav = $block.find('.post-nav__menu'),
      $navContent = $(),
      currentAnchorNumber = null,
      windowScrollTop = 0;

  $headings = $headings.filter(function () {
    if (!$(this).closest('.elementor-widget').hasClass('skip-from-nav')) return true;
  });

  if (!$headings.length) {
    $('.wrapper').addClass('hide-post-nav');
    return;
  }

  $headings.each(function (i) {
    const $this = $(this);

    const altName = $this.closest('.elementor-widget').attr('data-nav-name');
    var text = altName ? altName : $this.text();
    var slug = text.toLowerCase().replace(/^\s+/g, '').replace(/^[0-9].\s+/g, '').replace(/\s*$/, "").replace(/[^\w ]+/g, '').replace(/ +/g, '-');

    $this.prepend('<div class="post-anchor" id="' + slug + '"></div>');

    $navContent = $navContent.add('<li><a href="#' + slug + '"><span>' + text + '</span></a></li>');
  });

  var $anchors = $('.post-anchor');

  $nav.append($navContent);
  $block.addClass('initialized');

  function getActiveAnchor(number, checkDirection, previousFits) {
    if (number === null) number = 0;
    if (checkDirection) number += checkDirection;
    if (number < 0) return previousFits ? 0 : null;
    if (number >= $anchors.length) return $anchors.length - 1;

    var top = $anchors.eq(number).offset().top - windowScrollTop;
    if (checkDirection) {
      if (checkDirection > 0 && top + 180 > windowHeight) {
        return number - 1;
      }
      if (checkDirection < 0 && top < -50) {
        return previousFits ? number + 1 : number;
      }
    }
    if (top > -50) {
      return getActiveAnchor(number, -1, top + 180 <= windowHeight);
    }
    return getActiveAnchor(number, 1);
  }

  function checkScroll() {
    if (windowWidth < 768) return;
    windowScrollTop = $(window).scrollTop();

    var newCurrentAnchorNumber = getActiveAnchor(currentAnchorNumber);
    if (currentAnchorNumber === newCurrentAnchorNumber) {
      return;
    } else {
      currentAnchorNumber = newCurrentAnchorNumber;
    }
    $navContent.removeClass('current');
    if (currentAnchorNumber === null) return;

    var anchorId = $anchors.eq(currentAnchorNumber).attr('id');
    console.log(anchorId);
    console.log(currentAnchorNumber);
    $navContent.find('a[href="#' + anchorId + '"]').closest('li').addClass('current');
  }

  $(document).scroll(checkScroll);
  checkScroll();
}
