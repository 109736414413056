export default function($) {
  const $tabs = $('.tabs');

  $tabs.each(function () {
    const $tabsLink = $(this).find('.tabs__head-item');
    const $tabsContent = $(this).find('.tabs__content-item');
    const $tabsWrapper = $(this).find('.tabs__content');
    let newHeight = $(this).find('.shown').height();

    const itemChange = (i) => {
      $tabsWrapper.css('opacity', 0);
      $tabsWrapper.height(newHeight);

      $tabsLink.removeClass('active');
      $tabsLink.eq(i).addClass('active');
      newHeight = $tabsContent.eq(i).height();
      $tabsWrapper.height(newHeight);

      setTimeout(() => {
        $tabsContent.removeClass('shown');
        $tabsContent.eq(i).addClass('shown');
      }, 300);

      setTimeout(() => {
        $tabsWrapper.css('height', 'auto');
        $tabsWrapper.css('opacity', 1);
      }, 500);
    };

    $tabsLink.each(function (i) {
      const $this = $(this);
      $this.click((event) => {
        event.preventDefault();
        if ($this.hasClass('active')) return;
        itemChange(i);
      });
    });
  });
}
