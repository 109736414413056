export default function ($) {
  const $sliders = $('.team__slider');

  $sliders.each(function () {
    const $this = $(this);
    $this.slick({
      infinite: false,
      mobileFirst: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      easing: 'ease-in-out',
      responsive: [
        {
          breakpoint: 599,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 998,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            dots: false,
          },
        }],
    });
  });
}
