import Masonry from 'masonry-layout';
import sal from '../utils/sal';

export default function($) {
  const $caseResults = $('.case-results');
  sal({
    selector: '.case-results__item',
    threshold: 0.1,
  });

  $caseResults.each(function() {
    const $this = $(this);

    const grid = $this.find('.case-results__items')[0];
    const msnry = new Masonry(grid, {
      itemSelector: '.case-results__item',
    });
  });
}
