import 'regenerator-runtime/runtime.js';
import 'slick-carousel';
import caseResults from './modules/case-results';
import heroTestimonials from './modules/hero-testimonials';
import testimonials from './modules/testimonials';
import accordion from './modules/accordion-item';
import resultsSlider from './modules/results-slider';
import team from './modules/team';
import slider from './modules/slider';
import articles from './modules/articles';
import process from './modules/process';
import areas from './modules/areas';
import tabs from './modules/tabs';
import header from './modules/header';
import menu from './modules/menu';
import video from './modules/video';
import heroVideo from './modules/hero-video';
import postNav from './modules/post-nav';
import scrollAnimations from './modules/scroll-animations';
import appearance from './modules/appearance';
import headings from './modules/headings';
import pageReload from './modules/page-reload';
import videoBtns from './modules/video-btns';
import contactBar from './modules/contact-bar';

import windowWidthResize from './utils/windowWidthResize';

(function ($) {
  const initMethods = [
    caseResults,
    heroTestimonials,
    windowWidthResize,
    testimonials,
    accordion,
    resultsSlider,
    team,
    slider,
    articles,
    areas,
    tabs,
    process,
    header,
    menu,
    // video,
    heroVideo,
    postNav,
    scrollAnimations,
    appearance,
    headings,
    pageReload,
    videoBtns,
    contactBar
  ];

  $(() => {
    requestAnimationFrame(() => {
      for (let i = 0; i < initMethods.length; i += 1) {
        try {
          initMethods[i]($);
        } catch (error) {
          console.error(error);
        }
      }
    });
  });
}(window.jQuery));
