import { disablePageScroll } from "../utils/scroll";
import addPopup from "../utils/popup";

export default function ($) {
  const $body = $('body');
  const $sliders = $('.slider__items');
  // const $videoBtns = $('.slider__items .play-btn');
  // let $popup = $body.find('.video-popup');

  // if ($videoBtns.length) {
  //   if (!$popup.length) {
  //     addPopup();
  //     $popup = $body.find('.video-popup');
  //   }
  //
  //   $videoBtns.each(function() {
  //     const $this = $(this);
  //     const id = $this.attr('data-url');
  //
  //     $this.click(function (event) {
  //       event.preventDefault();
  //       $popup.find('.video-popup__frame').html('<iframe src="https://www.youtube.com/embed/' + id + '?showinfo=0&controls=0&autoplay=1" title="YouTube video player" frameborder="0" allowfullscreen></iframe>');
  //       $popup.addClass('shown');
  //       disablePageScroll(true);
  //     });
  //   });
  // }

  $sliders.each(function () {
    const $this = $(this);
    $this.slick({
      infinite: false,
      dots: true,
      dotsClass: 'slick-dots slick-dots--mono',
      speed: 600,
      easing: 'ease-in-out',
    });
  });
}
