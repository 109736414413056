import sal from '../utils/sal';
import wrap from '../utils/letters-wrap';

export default function () {
  // if ($(window).width() < 1200) return;
  let headings = document.querySelectorAll('.elementor-heading-title, .title-animate');

  headings = Array.from(headings).filter((heading) => {
    if (!heading.closest('.single-layout__content')) return true;
  });

  for (const heading of headings) {
    wrap(heading);
  }

  sal({
    selector: ['.elementor-heading-title', '.title-animate'],
  });
}
