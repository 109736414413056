export default function ($) {
  const $items = $('.accordion-item');

  $items.each(function () {
    const $this = $(this);
    const $head = $this.find('.accordion-item__head');
    const $body = $this.find('.accordion-item__body');
    const $content = $this.find('.accordion-item__body > div');
    const isFooter = !!$this.hasClass('footer-submenu');

    $head.click(function () {
      if (isFooter && $(window).width() > 1024) return;

      if (!$this.hasClass('opened')) {
        $body.css('height', $content.outerHeight() + 'px');
        $this.addClass('opened');
        setTimeout(function () {
          $body.css('height', 'auto');
        }, 400);
      } else {
        $body.css('height', $content.outerHeight() + 'px');
        $this.removeClass('opened');
        setTimeout(function () {
          $body.css('height', '0');
        }, 100);
      }
    });
  });
}
