// import lax from 'lax.js';
import { scrollerInit, scrollerUpdate } from './slick-scroller';

export default function ($) {
  const $areas = $('.areas__scroll');
  let windowWidth = $(window).width();
  let windowHeight = $(window).height();

  $areas.each(function () {
    const $this = $(this);
    const $wrapper = $this.find('.areas__items');
    let wrapperHeight = $wrapper.outerHeight();
    let wrapperLeft = $wrapper.offset().left;
    const $items = $wrapper.find('.areas__item');
    let itemsWidth = 0;

    $items.each(function() {
      itemsWidth += $(this).outerWidth();
    });

    function calcWidth() {
      return itemsWidth - $this.width();
    }

    $this.attr('data-scroll-size', calcWidth());

    $wrapper.slick({
      infinite: false,
      mobileFirst: true,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // edgeFriction: 0.5,
      swipeToSlide: true,
      touchThreshold: 10,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1199,
          settings: 'unslick',
        },
      ],
    });

    $(window).resize(() => {
      if (windowWidth === $(window).width()) return;
      if ($(window).width() < 1200) scrollerInit($this);
      if ($(window).width() >= 1200) $this.attr('data-scroll-size', calcWidth());
      windowWidth = $(window).width();
    });

    if (windowWidth >= 1200) {
      return;
    }
    scrollerInit($this);
    $this.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      scrollerUpdate($this, nextSlide);
    });

    // lax.init();
    // lax.addDriver('scrollY', () => window.scrollY);

  });
}
