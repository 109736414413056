import addPopup from "../utils/popup";
import {disablePageScroll} from "../utils/scroll";

export default function ($) {
  const $body = $('body');
  const $videoBtns = $('[data-youtube-id]');
  let $popup = $body.find('.video-popup');

  if ($videoBtns.length) {
    if (!$popup.length) {
      addPopup();
      $popup = $body.find('.video-popup');
    }

    $videoBtns.each(function () {
      const $this = $(this);
      const id = $this.attr('data-youtube-id');

      $this.click(function (event) {
        event.preventDefault();
        $popup.find('.video-popup__frame').html('<iframe src="https://www.youtube.com/embed/' + id + '?showinfo=0&controls=0&autoplay=1" title="YouTube video player" frameborder="0" allowfullscreen></iframe>');
        $popup.addClass('shown');
        disablePageScroll(true);
      });
    });
  }
}
