export default function() {
  const heroTestimonials = document.querySelectorAll('.hero__testimonials');

  for (const block of heroTestimonials) {
    const testimonial = block.querySelectorAll('.hero__testimonials-item');
    const testWrapper = block.querySelector('.hero__testimonials-items');
    const author = block.querySelectorAll('.hero__testimonials-author');
    let current = 0;
    let pause = false;
    let height = 220;

    const testimonialChange = (i) => {
      for (let a = 0; a < testimonial.length; a++) {
        testimonial[a].classList.remove('active');
        author[a].classList.remove('active');
      }

      testimonial[i].classList.add('active');
      author[i].classList.add('active');
      current = i;
    };

    const autoplay = () => {
      if (pause) {
        pause = false;
        return;
      }
      if (current === testimonial.length - 1) {
        testimonialChange(0);
        return;
      }
      testimonialChange(current + 1);
    };

    for (let i = 0; i < testimonial.length; i++) {
      height = testimonial[i].offsetHeight > height ? testimonial[i].offsetHeight : height;
      // console.log(testimonial[i].offsetHeight);
      author[i].addEventListener('click', function(event) {
        if (author[i].classList.contains('active')) return;
        if (testimonial[i].classList.contains('active')) return;
        testimonialChange(i);
        pause = true;
      });
    }

    // testWrapper.style.minHeight = height + 'px';

    setTimeout(function () {
      testimonialChange(current);
      setInterval(autoplay, 5000);
    }, 1800);
  }
}
