import lax from 'lax.js';

export default function ($) {
  let windowWidth = $(window).width();

  lax.init();

  lax.addDriver('scrollY', () => window.scrollY);

  // Progress Bar
  lax.addElements('.scroll-line', {
    scrollY: {
      scaleY: [
        ['elInY + screenHeight/7', 'elOutY + screenHeight/5'],
        [0, 1],
      ],
    },
  });

  // Areas Scroll
  const section = document.querySelector('.areas');

  setTimeout(function () {
    if (!section) return;
    let size = $('.areas__scroll').attr('data-scroll-size');
    const wrapper = section.querySelector('.areas__scroll');
    const slider = section.querySelector('.areas__items');
    let isFixed = false;
    let scrollY = 0;

    lax.addElements('.areas__items', {
      scrollY: {
        translateX: [
          ['elInY + screenHeight*1.5', 'elInY + screenHeight*2'],
          {
            1200: [0, 0],
            1440: [0, -1 * size],
          },
        ],
      },
    }, {}, slider);

    lax.addElements('.areas__scroll', {
      scrollY: {},
    },
    {
      onUpdate: function (driverValues, domElement) {
        if (scrollY === driverValues.scrollY[0] || windowWidth < 1200) return;
        const rect = section.getBoundingClientRect();
        if (rect.top <= 150) {
          if (!isFixed) {
            wrapper.style.cssText = `position: fixed; top: ${wrapper.getBoundingClientRect().top}px; width: ${section.offsetWidth}px`;
            wrapper.closest('.areas').classList.add('fixed');
            isFixed = true;
          }

          if (isFixed && rect.bottom <= 150 + wrapper.offsetHeight) {
            wrapper.style.cssText = `position: absolute; bottom: 0; width: 100%`;
            wrapper.closest('.areas').classList.remove('fixed');
            isFixed = false;
          }
        } else {
          wrapper.style.cssText = `position: static;`;
          wrapper.closest('.areas').classList.remove('fixed');
          isFixed = false;
        }
        scrollY = driverValues.scrollY[0];
      },
    }, wrapper);
  }, 1000);

  $(window).resize(() => {
    if (windowWidth === $(window).width() || $(window).width() < 1200) return;
    // size = $('.areas__scroll').attr('data-scroll-size');
    windowWidth = $(window).width();
  });
}
