export default function Testimonials($) {
  const $blockDesktop = $('.testimonials__items--desktop');
  const $blockMobile = $('.testimonials__items--mobile');

  $blockDesktop.each(function () {
    const $controls = $(this).siblings('.testimonials__controls--desktop');

    $(this).slick({
      arrows: true,
      dots: true,
      dotsClass: 'slick-dots slick-dots--mono',
      appendDots: $controls,
      appendArrows: $controls,
      infinite: false,
      responsive: [{
        breakpoint: 998,
        settings: 'unslick',
      }],
    });
  });

  $blockMobile.each(function () {
    const $controls = $(this).siblings('.testimonials__controls--mobile');

    $(this).slick({
      arrows: true,
      dots: true,
      dotsClass: 'slick-dots slick-dots--mono',
      appendDots: $controls,
      appendArrows: $controls,
      infinite: false,
      mobileFirst: true,
      responsive: [{
        breakpoint: 999,
        settings: 'unslick',
      }],
    });
  });
}
