export default function ($) {
  const $header = $('.header-template').eq(0);
  let wait = false;
  let lastVal = window.scrollY;
  let actualVal = window.scrollY;
  let isFixed = false;
  if (!$header.length) return;

  function checkScroll(pos, lastPos) {
    if ($header.hasClass('mobile-menu-opened')) return;

    if (pos > 10) {
      if (isFixed) return;
      $header.addClass('header-sticked');
      isFixed = true;

    } else if (pos <= 10) {
      if (!isFixed) return;
      $header.removeClass('header-sticked');
      isFixed = false;
    }
  }

  document.addEventListener('scroll', function () {
    actualVal = window.scrollY;
    if (wait) return;

    checkScroll(actualVal, lastVal);
    wait = true;

    setTimeout(function () {
      wait = false;

      if (lastVal !== actualVal) {
        checkScroll(actualVal, lastVal);
      }
    }, 200);

    lastVal = actualVal;
  });

  checkScroll(actualVal, lastVal);
}
