import { disablePageScroll, enableScroll } from '../utils/scroll';

export default function ($) {
  const $btn = $('.elementor-menu-toggle');
  const $body = $('body');
  const $header = $('.header-template');

  $btn.click(() => {
    if ($(window).width() >= 1200) return;
    $body.toggleClass('menu-shown');
    if ($body.hasClass('menu-shown')) {
      disablePageScroll();
      $header.removeClass('header-sticked');
    } else {
      enableScroll();
    }
  });

  $(window).resize(() => {
    if ($(window).width() < 1200) return;
    $body.removeClass('menu-shown');
    enableScroll();
  });
}
