export default function ($) {
  const $sliders = $('.articles__items');

  $sliders.each(function () {
    const $this = $(this);
    $this.slick({
      arrows: true,
      dots: true,
      dotsClass: 'slick-dots slick-dots--mono',
      infinite: false,
      mobileFirst: true,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 999,
          settings: 'unslick',
        },
      ],
    });
  });
}
