import sal from '../utils/sal';

export default function ($) {
  const $blocks = $('.process');

  $blocks.each(function () {
    const $this = $(this);
    const $steps = $this.find('.process__item');
    const startPoint = $steps.eq(0).offset();
    const endPoint = $steps.eq($steps.length - 1).offset();
    const $line = $('<div>', { class: 'scroll-line' });
    let windowWidth = $(window).width();

    function getDistance() {
      return (endPoint.top - startPoint.top);
    }

    function setSize() {
      $line.height(getDistance());
    }

    $steps.eq(0).find('.process__item-path').prepend($line);

    // Recalculate line height
    $(window).resize(() => {
      if (windowWidth === $(window).width()) return;
      setSize();
      windowWidth = $(window).width();
    });

    // $(window).scroll(function () {
    //   let attr = $line.attr('style');
    //   // attr = attr.split(attr.search('scaleY('));
    //   console.log(attr.split(attr.search('scaleY(')));
    // });

    setTimeout(function() {
      setSize();
    }, 3000);
  });

  sal({
    selector: '.process__item',
    threshold: 1,
    once: true,
    animateClassName: 'animated',
  });
}
