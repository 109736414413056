import {enableScroll} from './scroll';

export default function addPopup() {
  const body = document.querySelector('body');
  if (body.querySelector('.video-popup')) return;
  let popup = document.createElement('div');
  popup.classList.add('video-popup');
  popup.innerHTML = '<div class="video-popup__overlay"></div><div class="video-popup__frame"></div><a class="video-popup__close" href="#"></a>';
  body.appendChild(popup);

  const closePopup = (event) => {
    event.preventDefault();
    popup.classList.remove('shown');
    popup.querySelector('.video-popup__frame').innerHTML = '';
    setTimeout(function () {
      enableScroll();
    }, 500);
  };

  popup.querySelectorAll('.video-popup__close, .video-popup__overlay').forEach(function (element) {
    element.addEventListener('click', closePopup);
  });
}
