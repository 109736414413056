export default function () {
  const footer = document.querySelector('.elementor-location-footer');
  const footerBottom = footer.querySelector('.footer-bottom > .elementor-container');
  const bar = footer.querySelector('.contact-bar');
  const form = bar.querySelector('.elementor-form');

  if (localStorage.getItem('ArashLawFreeCaseSubmitted') === 'true' || !bar) {
    footer.classList.add('bar-hidden');
    return;
  } else {
    let barHeight = bar.offsetHeight;
    footerBottom.style.paddingBottom = barHeight + 'px';
  }

  let observer = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      for (const node of mutation['addedNodes']) {
        if (node.classList.contains('elementor-message-success')) {
          footer.classList.add('bar-hidden');
          footerBottom.style.paddingBottom = '0';
          observer.disconnect();
          localStorage.setItem('ArashLawFreeCaseSubmitted', 'true');
        }
      }
    }
  });

  observer.observe(form, {
    childList: true,
    subtree: false,
    characterDataOldValue: false,
  });
}
