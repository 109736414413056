import { disablePageScroll, enableScroll } from '../utils/scroll';
import addPopup from "../utils/popup";

export default function ($) {
  const $hero = $('.hero');
  const $body = $('body');
  let $popup = $body.find('.video-popup');

  $hero.each(function() {
    const $this = $(this);
    const $btn = $this.find('.play-btn');
    const id = $btn.attr('data-video');
    if (!$btn.length) return;

    if (!$popup.length) {
      addPopup();
      $popup = $body.find('.video-popup');
    }

    $btn.click(function(event) {
      event.preventDefault();
      $popup.find('.video-popup__frame').html('<iframe src="https://www.youtube.com/embed/' + id + '?showinfo=0&controls=0&autoplay=1" title="YouTube video player" frameborder="0" allowfullscreen></iframe>');
      $popup.addClass('shown');
      disablePageScroll(true);
    });
  });
}
